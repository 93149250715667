import { useFormContext } from 'react-hook-form';
import { CustomField } from 'apps/commerce/common/checkout/types/CheckoutSettings';
import { emailRegex } from 'apps/commerce/common/checkout/constants';

const useCheckoutSettings = () => {
  const { getValues, watch } = useFormContext();
  const { checkoutSettings } = getValues();
  const customFieldValues = watch('customFieldValues');

  const isCustomFieldsCompleted = checkoutSettings.customFields?.every((field: CustomField) => {
    const value = customFieldValues?.[field.handle] || '';
    const isEmpty = value === '';
    const isEmailField = field.type === 'EmailField';
    // Required fields cannot be empty
    if (field.required && isEmpty) return false;
    // Email fields must be valid
    if (isEmailField && !isEmpty && !emailRegex.test(value)) return false;
    return true;
  });

  return {
    ...checkoutSettings,
    isCustomFieldsCompleted,
  };
};

export default useCheckoutSettings;
