import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input } from '@kajabi/sage-react';

function EmailInput({ disabled, defaultValue, label, emailField, placeholder, validateOnBlur }) {
  const [email, setEmail] = React.useState(defaultValue);
  const {
    setValue,
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation();

  useEffect(() => {
    if (defaultValue) {
      setValue(emailField, defaultValue, { shouldValidate: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Run once only!

  const handleChange = (event) => {
    setEmail(event.target.value);
    if (!validateOnBlur) {
      setValue(emailField, event.target.value, { shouldValidate: true });
    }
  };

  const handleBlur = (event) => {
    if (validateOnBlur) {
      setValue(emailField, event.target.value, { shouldValidate: true });
    }
  };

  const emailError =
    errors && emailField.split('.').reduce((res, prop) => res && res[prop], errors);

  const emailErrorMessage =
    emailError?.type === 'i18n-error-message'
      ? t(emailError.message || 'form.invalid_email')
      : t('form.invalid_email');

  return (
    <Input
      inputType="email"
      label={label}
      name={label}
      id={emailField}
      value={email}
      onChange={handleChange}
      onBlur={handleBlur}
      placeholder={t(placeholder)}
      disabled={disabled}
      hasError={emailError}
      message={emailError ? emailErrorMessage : null}
    />
  );
}

EmailInput.propTypes = {
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
  label: PropTypes.string,
  emailField: PropTypes.string,
  placeholder: PropTypes.string,
  validateOnBlur: PropTypes.bool,
};

EmailInput.defaultProps = {
  disabled: false,
  defaultValue: '',
  label: null,
  emailField: 'email',
  placeholder: 'form.email',
  validateOnBlur: false,
};

export default EmailInput;
