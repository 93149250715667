import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input } from '@kajabi/sage-react';
import CountryInput from './CountryInput';
import SubdivisionInput from './SubdivisionInput';

function AddressInput({ disabled }) {
  const [addressLine1, setAddressLine1] = React.useState('');
  const [addressLine2, setAddressLine2] = React.useState('');
  const [city, setCity] = React.useState('');
  const [country, setCountry] = React.useState('');
  const [subdivision, setSubdivision] = React.useState('');
  const [zip, setZip] = React.useState('');
  const {
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation();
  const { address, site } = getValues();

  const handleChange = (event, key, setFn) => {
    setFn(event.target.value);
    setValue(`address.${key}`, event.target.value, { shouldValidate: true });
  };

  const buildErrorMessage = useCallback(
    (key) => {
      if (errors.address && errors.address[key]) {
        return `${t('form.empty_field', { field: t(`form.${key}`).toLowerCase() })}`;
      }
      return null;
    },
    [errors.address, t],
  );

  const handleCountryChange = (event) => {
    handleChange(event, 'country', setCountry);
  };

  const handleSubdivisionRefresh = (subdivisions) => {
    // Set the value back to an empty string so that the list defaults to the first entry
    setSubdivision('');
    setValue('address.subdivisionRequired', subdivisions.length > 0);
    setValue('address.subdivision', '', { shouldValidate: true });
  };

  return (
    <div>
      <Input
        label={t('form.addressLine1')}
        placeholder={t('form.addressLine1')}
        id="addressLine1"
        value={addressLine1}
        onChange={(e) => handleChange(e, 'addressLine1', setAddressLine1)}
        disabled={disabled}
        hasError={errors.address && !!errors.address.addressLine1}
        message={buildErrorMessage('addressLine1')}
      />
      <Input
        placeholder={t('form.addressLine2')}
        id="addressLine2"
        value={addressLine2}
        onChange={(e) => handleChange(e, 'addressLine2', setAddressLine2)}
        disabled={disabled}
        hasError={errors.address && !!errors.address.addressLine2}
        message={buildErrorMessage('addressLine2')}
      />
      <div className="input-group sage-spacer-bottom">
        <Input
          className="city-input"
          placeholder={t('form.city')}
          id="city-input"
          value={city}
          onChange={(e) => handleChange(e, 'city', setCity)}
          disabled={disabled}
          hasError={errors.address && !!errors.address.city}
          message={buildErrorMessage('city')}
        />
        <Input
          placeholder={t('form.zip')}
          id="addressZip"
          value={zip}
          onChange={(e) => handleChange(e, 'zip', setZip)}
          disabled={disabled}
          hasError={errors.address && !!errors.address.zip}
          message={buildErrorMessage('zip')}
        />
      </div>
      <CountryInput
        id="addressCountry"
        value={country}
        onChange={handleCountryChange}
        placeholder={t('form.country')}
        disabled={disabled}
        hasError={errors.address && !!errors.address.country}
        message={errors.address && errors.address.country ? t('form.country') : null}
      />
      <SubdivisionInput
        id="addressSubdivision"
        value={subdivision}
        countryCode={address?.country}
        onChange={(e) => handleChange(e, 'subdivision', setSubdivision)}
        onListRefresh={handleSubdivisionRefresh}
        placeholder={t('form.state')}
        disabled={disabled}
        hasError={errors.address && !!errors.address.subdivision}
        message={errors.address && errors.address.subdivision ? t('form.state') : null}
      />
    </div>
  );
}

AddressInput.propTypes = {
  disabled: PropTypes.bool,
};

AddressInput.defaultProps = {
  disabled: false,
};

export default AddressInput;
