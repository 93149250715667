import { Input, Link } from '@kajabi/sage-react';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import SavedCardDetails from 'apps/commerce/common/checkout/types/SavedCardDetails';
import { VisaIcon } from '../images/visa';
import { AmexIcon } from '../images/amex';
import { MasterCardIcon } from '../images/mastercard';
import { DiscoverIcon } from '../images/discover';

export interface SavedCardProps {
  savedCardDetails: SavedCardDetails;
}

const SavedCard: React.FC<SavedCardProps> = ({ savedCardDetails }) => {
  const { setValue, watch } = useFormContext();
  const { t } = useTranslation();
  const useSavedCard = watch('useSavedCard');
  const placeholder = () => {
    switch (savedCardDetails.brand.toLowerCase()) {
      case 'amex':
        return '•••• •••••• •';
      case 'diners club':
        return '•••• •••••• ';
      default:
        return '•••• •••• •••• ';
    }
  };
  const description = `${placeholder()}${savedCardDetails.ccLast4}`;
  const cardIcon = () => {
    switch (savedCardDetails.brand.toLowerCase()) {
      case 'visa':
        return <VisaIcon />;
      case 'amex':
        return <AmexIcon />;
      case 'mastercard':
        return <MasterCardIcon />;
      case 'discover':
        return <DiscoverIcon />;
      default:
        return null;
    }
  };

  const toggleUseSavedCard = () => {
    setValue('useSavedCard', !checked);
    setChecked(!checked);
  };

  const [checked, setChecked] = useState(useSavedCard);

  return (
    <>
      {useSavedCard && (
        <div style={{ position: 'relative' }}>
          <Input
            label={t('form.card_number')}
            disabled={true}
            value={description}
            id="saved-card"
          />
          <div
            style={{
              position: 'absolute',
              top: '43px',
              right: '15px',
              scale: '1.3',
            }}
          >
            {cardIcon()}
          </div>
          <div
            style={{
              position: 'absolute',
              top: '39px',
              right: '59px',
            }}
          >
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link
              onClick={() => toggleUseSavedCard()}
              style={Link.COLORS.SECONDARY}
              removeUnderline={true}
              className="change-btn"
              data-testid="change-saved-card-btn"
            >
              {t('form.change')}
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default SavedCard;
