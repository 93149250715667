import React from 'react';
import ToastContext from '../context/toast/ToastContext';

const useToast = () => {
  const context = React.useContext(ToastContext);

  if (context === undefined) {
    throw new Error('useToast cannot be used outside of the Toast Provider');
  }
  return context;
};

export default useToast;
