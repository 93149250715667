import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import da from './da.json';
import de from './de.json';
import en from './en.json';
import es from './es.json';
import fi from './fi.json';
import fr from './fr.json';
import hu from './hu.json';
import it from './it.json';
import ja from './ja.json';
import nl from './nl.json';
import nn from './nn.json';
import pl from './pl.json';
import pt from './pt.json';
import ru from './ru.json';
import sv from './sv.json';
import tr from './tr.json';

i18next
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: 'en',
    interpolation: {
      // React already does escaping
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
    // Using simple hardcoded resources
    resources: {
      da,
      de,
      en,
      es,
      fi,
      fr,
      hu,
      it,
      ja,
      nl,
      nn,
      pl,
      pt,
      ru,
      sv,
      tr,
    },
    supportedLngs: [
      'da',
      'de',
      'en',
      'es',
      'fi',
      'fr',
      'hu',
      'it',
      'ja',
      'nl',
      'nn',
      'pl',
      'pt',
      'ru',
      'sv',
      'tr',
    ],
    lng: window.CLIENT_INFO?.locale || 'en',
  });

export default i18next;
