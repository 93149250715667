import React from 'react';
import ReactDOM from 'react-dom';
import { Toast } from '@kajabi/sage-react';

import useToast from 'apps/commerce/common/checkout/hooks/useToast';

function SageToast() {
  const { isActive, hideToast, ...props } = useToast();
  return ReactDOM.createPortal(
    <Toast isActive={isActive} onDismiss={hideToast} {...props} />,
    document.body,
  );
}

export default SageToast;
