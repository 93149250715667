import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select } from '@kajabi/sage-react';
import { getRequest } from 'apps/commerce/popup_checkout/util';

function CountryInput({
  id,
  label,
  value,
  onChange,
  placeholder,
  placeholderDisabled,
  disabled,
  hasError,
  message,
}) {
  const [countryList, setCountryList] = useState();
  const endpoint = '/api/checkout/countries';

  useEffect(() => {
    let isMounted = true;
    getRequest(endpoint).then((response) => {
      if (isMounted && response.status === 200) {
        setCountryList(formatOptions(response.data.data.countries));
      }
    });
    return () => {
      isMounted = false;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const formatOptions = (data) => {
    const placeholderOption = {
      disabled: placeholderDisabled,
      label: placeholder,
      value: '',
    };
    const formattedData = data.map((d) => ({ label: d[0], value: d[1] }));
    formattedData.unshift(placeholderOption);
    return formattedData;
  };

  return (
    <Select
      id={id}
      data-testid={id}
      label={label}
      value={value}
      onChange={onChange}
      disabled={disabled}
      options={countryList}
      hasError={hasError}
      message={message}
    />
  );
}

CountryInput.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  placeholderDisabled: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  message: PropTypes.string,
};

CountryInput.defaultProps = {
  label: '',
  value: '',
  disabled: false,
  placeholderDisabled: true,
  hasError: false,
  message: null,
};

export default CountryInput;
