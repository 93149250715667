import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Frame } from '@kajabi/sage-react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import useCheckoutSettings from 'apps/commerce/common/checkout/hooks/useCheckoutSettings';

export const ServiceAgreement = ({ disabled }) => {
  const { t } = useTranslation();
  const { setValue } = useFormContext();
  const { serviceAgreementRequired, serviceAgreementCustomText } = useCheckoutSettings();

  const [serviceAgreementChecked, setServiceAgreementChecked] = React.useState(false);
  const isServiceAgreementRequired = serviceAgreementRequired || serviceAgreementCustomText;

  const handleChange = () => {
    const newValue = !serviceAgreementChecked;
    setServiceAgreementChecked(newValue);
    setValue('serviceAgreementChecked', newValue);
  };

  if (!isServiceAgreementRequired) return null;
  return (
    <>
      <Frame className="service-agreement" data-testid="service-agreement">
        <Checkbox
          id="service-agreement-checkbox"
          label={
            serviceAgreementCustomText
              ? t('form.custom_service_agreement')
              : t('form.service_agreement')
          }
          className="terms-checkbox"
          checked={serviceAgreementChecked}
          onChange={handleChange}
          disabled={disabled}
        />
        {serviceAgreementCustomText && (
          // eslint-disable-next-line react/no-danger
          <div
            data-testid="custom-service-agreement"
            dangerouslySetInnerHTML={{ __html: serviceAgreementCustomText }}
            className="form-control service-agreement-text"
          />
        )}
      </Frame>
    </>
  );
};

ServiceAgreement.propTypes = {
  disabled: PropTypes.bool,
};

ServiceAgreement.defaultProps = {
  disabled: false,
};

export default ServiceAgreement;
