import React from 'react';
import PropTypes from 'prop-types';
import { Select } from '@kajabi/sage-react';
import { getRequest } from 'apps/commerce/popup_checkout/util';

function SubdivisionInput({
  id,
  value,
  placeholder,
  onChange,
  onListRefresh,
  disabled,
  countryCode,
  hasError,
  message,
}) {
  const [subdivisionList, setSubdivisionList] = React.useState();
  const endpoint = `/api/checkout/subdivisions`;

  React.useEffect(() => {
    if (countryCode) {
      getRequest(endpoint, { params: { country: countryCode } }).then((response) => {
        if (response.status === 200) {
          const { subdivisions } = response.data.data;
          setSubdivisionList(formatOptions(subdivisions));
          onListRefresh(subdivisions);
        }
      });
    } else {
      setSubdivisionList(formatOptions([]));
    }
  }, [countryCode]);

  const formatOptions = (data) => {
    const placeholderOption = {
      disabled: true,
      label: placeholder,
      value: '',
    };
    const formattedData = data.map((d) => ({ label: d[0], value: d[1] }));
    formattedData.unshift(placeholderOption);
    return formattedData;
  };

  return (
    <Select
      id={id}
      data-testid={id}
      label=""
      value={value}
      onChange={onChange}
      disabled={disabled}
      options={subdivisionList}
      hasError={hasError}
      message={message}
    />
  );
}

SubdivisionInput.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onListRefresh: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  countryCode: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  message: PropTypes.string,
};

SubdivisionInput.defaultProps = {
  value: '',
  disabled: false,
  countryCode: null,
  hasError: false,
  message: null,
};

export default SubdivisionInput;
