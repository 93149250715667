import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '@kajabi/sage-react';
import useCheckoutSettings from 'apps/commerce/common/checkout/hooks/useCheckoutSettings';

function OptInCheckbox({ disabled }) {
  const { setValue } = useFormContext();
  const { showOptIn, optInDefaultValue, optInTextOverride } = useCheckoutSettings();

  const [checked, setChecked] = React.useState(optInDefaultValue);
  const { t } = useTranslation();
  useEffect(() => {
    setValue('optin', optInDefaultValue, { shouldValidate: true });
  }, []);
  const handleChange = () => {
    const newValue = !checked;
    setChecked(newValue);
    setValue('optin', newValue, { shouldValidate: true });
  };

  if (!showOptIn) return null;

  return (
    <Checkbox
      label={optInTextOverride || t('form.opt_in')}
      id="optin"
      name="optin"
      checked={checked}
      onChange={handleChange}
      disabled={disabled}
    />
  );
}

OptInCheckbox.propTypes = {
  disabled: PropTypes.bool,
};

OptInCheckbox.defaultProps = {
  disabled: false,
};

export default OptInCheckbox;
