import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '@kajabi/sage-react';

function SaveCardCheckbox({ disabled, saveCardDefaultValue }) {
  const { setValue } = useFormContext();
  const [checked, setChecked] = React.useState(saveCardDefaultValue);
  const { t } = useTranslation();

  useEffect(() => {
    setValue('saveCard', saveCardDefaultValue, { shouldValidate: true });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = () => {
    const newValue = !checked;
    setChecked(newValue);
    setValue('saveCard', newValue, { shouldValidate: true });
  };

  return (
    <div className="sage-spacer-top">
      <Checkbox
        label={t('form.store_card')}
        id="store_card"
        name="store_card"
        checked={checked}
        onChange={handleChange}
        disabled={disabled}
        className="store-card-checkbox"
      />
    </div>
  );
}

SaveCardCheckbox.propTypes = {
  disabled: PropTypes.bool,
  saveCardDefaultValue: PropTypes.bool.isRequired,
};

SaveCardCheckbox.defaultProps = {
  disabled: false,
};

export default SaveCardCheckbox;
