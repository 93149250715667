import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input } from '@kajabi/sage-react';

function PhoneInput({ disabled }) {
  const [phone, setPhone] = React.useState('');
  const {
    setValue,
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation();

  const handleChange = (event) => {
    setPhone(event.target.value);
    setValue('phone', event.target.value, { shouldValidate: true });
  };

  return (
    <Input
      label={t('form.phone')}
      placeholder={t('form.phone')}
      id="phone"
      value={phone}
      onChange={handleChange}
      disabled={disabled}
      hasError={errors && !!errors.phone}
      message={errors && errors.phone ? t('form.invalid_phone') : null}
    />
  );
}

PhoneInput.propTypes = {
  disabled: PropTypes.bool,
};

PhoneInput.defaultProps = {
  disabled: false,
};

export default PhoneInput;
